import { createContext } from "react";
import { object, string } from "yup";

export type Product = {
  id: string;
  type: "domain" | "webhosting" | "ssl";
  params: DomainParams | WebhostingParams;
};

export type DomainParams = {
  years: number;
  price: string;
  nextYearPrice: string;
  domain: string;
  tld: string;
};

export type WebhostingParams = {
  selectId: number;
  months: number;
  price: string;
  name: string;
};

export type BillingInformationPerson = {
  id?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  state?: string;
};

export type BillingInformationCompany = {
  id?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  ico?: string;
  dic?: string;
  company?: true;
};

export type User = {
  id?: string;
  name?: string;
  email?: string;
  surname?: string;
  tel?: string;
  new?: boolean;
  password?: string;
};

export let userSchema = object({
  name: string().required(),
  surname: string().required(),
  email: string().email().required(),
  tel: string()
    .matches(
      /^(?:\+421|\+420)\s\d{9}$/,
      "Telefónne číslo musí byť vo formáte +421 940223123 alebo +420 940223123"
    )
    .required(),
  password: string().required(),
});

export let billingInformationPersonSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  street: string().required(),
  postalCode: string()
    .matches(/^\d{5}$/, "PSČ má špatný formát")
    .required(),
  city: string().required(),
  state: string().required(),
});

export let billingInformationCompanySchema = object({
  name: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  street: string().required(),
  city: string().required(),
  postalCode: string()
    .matches(/^\d{5}$/, "PSČ má špatný formát")
    .required(),
  state: string().required(),
  ico: string().required(),
  dic: string().required(),
});

export enum PaymentType {
  ApplePay = "apple-pay",
  GooglePay = "google-pay",
  Card = "card",
}

export type CartContextType = React.PropsWithChildren & {
  products: Product[];
  addProduct: (product: Product) => void;
  addProducts: (products: Product[]) => void;
  billingInformationPerson?: BillingInformationPerson;
  setBillingInformatonPerson: (billingInformation: BillingInformationPerson | undefined) => void;
  billingInformationCompany?: BillingInformationCompany;
  setBillingInformatonCompany: (billingInformation: BillingInformationCompany | undefined) => void;
  user?: User;
  payment?: PaymentType;
  setPayment: (payment: PaymentType) => void;
  setUser: (user: User) => void;
  step?: number;
  setStep: (step: number) => void;
  errors: any;
  setErrors: (errors: any) => void;
  validate: () => void;
  vop: boolean;
  setVop: (vop: boolean) => void;
  thirdVOP: boolean;
  setThirdVOP: (thirdVOP: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export default createContext<CartContextType>({} as CartContextType);
