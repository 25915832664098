import React, { ReactElement, useContext, useEffect, useState } from "react";
import CartContext, {
  BillingInformationCompany,
  billingInformationCompanySchema,
  BillingInformationPerson,
  billingInformationPersonSchema,
  CartContextType,
  PaymentType,
  Product,
  User,
  userSchema,
} from "./CartContext";
import { useCookies } from "react-cookie";
import { ValidationError } from "yup";
import { Data, useApiQuery } from "../api/UseApiQuery";

export default function CartProvider(props: CartContextType): ReactElement {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [products, addProducts] = useState<Product[]>([]);
  const [payment, setPayment] = useState<PaymentType>(PaymentType.Card);
  const [vop, setVop] = useState<boolean>(false);
  const [thirdVOP, setThirdVOP] = useState<boolean>(false);
  const [billingInformationPerson, setBillingInformatonPerson] =
    useState<BillingInformationPerson>();
  const [billingInformationCompany, setBillingInformatonCompany] =
    useState<BillingInformationCompany>();
  const [cookies, setCookie, removeCookie] = useCookies(["products"]);
  const [step, setStep] = useState<number>(1);
  const [errors, setErrors] = useState<any>({});
  const { execute, data, error } = useApiQuery<Data>({
    action: "web-api/user/get",
    pause: true,
  });
  const {
    execute: executeCart,
    data: cartData,
    loading: cartLoading,
  } = useApiQuery<Data>({
    action: user?.id ? "web-api/user/cart/submit" : "web-api/cart/submit",
    pause: true,
  });

  useEffect(() => {
    if (cartData && cartData?.url) {
      setCookie("products", null, { path: "/" });
      setBillingInformatonCompany(undefined);
      setBillingInformatonPerson(undefined);
      setTimeout(() => {
        window.location.href = cartData.url;
      }, 0);
    }
  }, [cartData]);

  useEffect(() => {
    if (data && data.id) {
      setUser({
        id: data.id,
        name: data.name,
        email: data.email,
        surname: data.surname,
        tel: data.tel,
        new: false,
      });
    } else if (error) {
      localStorage.removeItem("token");
    }
  }, [data, error]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      execute();
    }
  }, [typeof window !== "undefined" && localStorage.getItem("token")]);

  useEffect(() => {
    const savedProducts = cookies.products;
    if (savedProducts) {
      addProducts(savedProducts);
    }
  }, []);

  const addProduct = (data: Product) => {
    const updatedProducts = [...products, data];
    addProducts(updatedProducts);
    setCookie("products", updatedProducts, { path: "/" });
  };

  const validate = async () => {
    let newErrors: { [key: string]: any } = {};

    if (!user?.id) {
      try {
        await userSchema.validate(user, { abortEarly: false });
      } catch (err) {
        const formattedErrors: { [key: string]: string } = {};
        (err as ValidationError).inner.forEach((error: any) => {
          formattedErrors[error.path] = error.message;
        });
        if (Object.keys(formattedErrors).length > 0) {
          newErrors.user = formattedErrors;
        }
      }
    }

    if (billingInformationPerson || !billingInformationCompany) {
      if (!billingInformationPerson?.id) {
        try {
          await billingInformationPersonSchema.validate(billingInformationPerson, {
            abortEarly: false,
          });
        } catch (err) {
          const formattedErrors: { [key: string]: string } = {};
          (err as ValidationError).inner.forEach((error: any) => {
            formattedErrors[error.path] = error.message;
          });
          if (Object.keys(formattedErrors).length > 0) {
            newErrors.billingInformationPerson = formattedErrors;
          }
        }
      }
    }

    if (billingInformationCompany) {
      if (!billingInformationCompany?.id) {
        try {
          await billingInformationCompanySchema.validate(billingInformationCompany, {
            abortEarly: false,
          });
        } catch (err) {
          const formattedErrors: { [key: string]: string } = {};
          (err as ValidationError).inner.forEach((error: any) => {
            formattedErrors[error.path] = error.message;
          });
          if (Object.keys(formattedErrors).length > 0) {
            newErrors.billingInformationCompany = formattedErrors;
          }
        }
      }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (Object.keys(newErrors).length === 0 && vop && thirdVOP && payment) {
      setErrors({});
      executeCart({ payment, products, user, billingInformationPerson, billingInformationCompany });
    }
  };

  useEffect(() => {
    setLoading(cartLoading || false);
  }, [cartLoading]);

  return (
    <CartContext.Provider
      value={{
        user,
        setUser,
        products,
        addProduct,
        addProducts,
        billingInformationPerson,
        setBillingInformatonPerson,
        billingInformationCompany,
        setBillingInformatonCompany,
        step,
        errors,
        setErrors,
        setStep,
        validate,
        payment,
        setPayment,
        vop,
        setVop,
        thirdVOP,
        setThirdVOP,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
