import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";

export type ApiQueryArgs = {
  pause?: boolean;
  action: string;
  onLoadScreen?: boolean;
  variables?: Variables;
  responseType?: "json" | "blob";
};

export type ApiQuery<TData> = {
  data?: TData;
  error?: any;
  loading?: boolean;
  action: string;
  pause: boolean;
  setVariables: (variables?: Variables) => void;
  setPause: (pause: boolean) => void;
  execute: (variables?: any) => void;
  variables?: Variables;
};

export type Variables = {
  id?: string | number | null;
  take?: number;
  skip?: number;
  where?: any;
  search?: string;
  data?: Data;
};

export type Data = {
  [x: string]: any;
};

export const useApiQuery = <TData extends any>(props: ApiQueryArgs): ApiQuery<TData> => {
  const { action, variables: initialVariables, onLoadScreen = true, responseType = "json" } = props;
  const [pause, setPause] = useState<boolean>(props.pause ?? false);
  const [data, setData] = useState<TData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [variables, setVariables] = useState<Variables | null>(initialVariables ?? null);

  const fetchData = async (variables: Variables | null) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "post",
        withXSRFToken: true,
        withCredentials: true,
        url: `https://client.feather-host.eu/${action}`,
        // url: `http://localhost:3000/${action}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          ...variables,
        },
        responseType: responseType,
      });
      setData(response.data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!pause) {
      if (onLoadScreen === true) {
        fetchData(variables);
      }
    }
  }, [pause, action, variables, onLoadScreen]);

  // Funkcia na manuálne spustenie API počas behu komponenty
  const execute = (newVariables?: any) => {
    fetchData(newVariables ?? variables);
  };

  return {
    data: data,
    loading: loading as boolean,
    error: error as AxiosError,
    execute,
    setVariables: setVariables as (variables?: Variables) => void,
    variables: variables as Variables,
    action: action,
    pause: pause as boolean,
    setPause: setPause,
  };
};
